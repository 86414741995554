<template>
  <div class="seller-account">
    <van-nav-bar :title="receiveAddress.name" left-arrow @click-left="goBack" :border="false">
      <template #right>
          <van-icon name="plus" size="20" @click="goToAdd"/>
      </template>
    </van-nav-bar>
    <van-pull-refresh v-model="refreshing" @refresh="refreshData">
      <van-list
        v-if="dataList.length >0"
        v-model:loading="loading"
        :finished="finished"
        :finished-text="$t('common.noMore')"
        @load="loadData"
      >
        <div class="seller-account-block" v-for="item in dataList" :key="item.id" @click="goToDetail(item)">
          <div class="seller-account-info">
            <div class="seller-account-name">{{$t('field.account')}}: {{item.sellerAccount}}</div>
            <div class="seller-account-realname">{{$t('field.realname')}}: {{item.realName || '-'}}</div>
          </div>
          <div :class="`seller-account-status status${statusIndex(item)}`">{{statusTextList[statusIndex(item)]}}</div>
        </div>
      </van-list>
      <div v-else>
        <van-empty :description="$t('common.noData')" />
      </div>
    </van-pull-refresh>
    <van-popup v-model:show="showDetail" round>
      <div class="detail-dialog">
        <van-cell 
          :title="$t('field.account')" 
          :value="detail.sellerAccount"
          :border="false"
          class="detail-account"
        />
        <van-cell :title="$t('field.address')" :border="false">
          <template #label>
            <p>{{detail.qrCode}}</p>
            <p @click="copy(detail.qrCode)"><van-icon class-prefix="my-icon" name="copy" size="20" /></p>
          </template>
        </van-cell>
        <vue-qrcode :value="detail.qrCode" :options="{ width: 200 }" />
        <van-button
          v-if="detail.reviewed === 1"
          type="primary"
          round
          @click="handleToggleSellerAccount"
        >{{detail.status == 1 ? $t('button.open') : $t('button.close') }}</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { Toast } from 'vant';
import useClipboard from 'vue-clipboard3';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import i18n from '@/assets/lang/index';
import { querySellerAccountList, enableSellerAccount, disableSellerAccount } from "@/api";

export default {
  components: {
    [VueQrcode.name]: VueQrcode,
  },
  
  setup() {
    const router = useRouter();
    const goBack = () => router.back();
    
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const dataList = ref([]);
    
    const { toClipboard } = useClipboard();
    const copy = async (text) => {
      try {
        await toClipboard(text);
        Toast(i18n.global.t('notify.copySuccess'));
      } catch (e) {
        console.error(e)
      }
    }
   
    return {
      goBack,
      loading,
      finished,
      refreshing,
      dataList,
      copy,
    };
  },
  
  data() {
    return {
      receiveAddress: JSON.parse(localStorage.getItem('receiveAddress')),
      current: 1,
      statusTextList: [
        i18n.global.t('status.inReview'),
        i18n.global.t('status.reviewFailed'),
        i18n.global.t('status.unopened'),
        i18n.global.t('status.opened'),
      ],
      detail: {},
      showDetail: false,
    };
  },
  
  mounted() {
    this.fetchData();
  },
  
  methods: {
    fetchData() {
      querySellerAccountList({
        pageSizeStr: 10,
        pageNumberStr: this.current,
        type: this.receiveAddress.type,
      }).then(res => {
        this.dataList = this.dataList.concat(res.data || []);
        this.current = res.pageNumber + 1;
        this.loading = false;
        if (res.totalNumber <= res.pageNumber * res.pageSize) {
          this.finished = true;
        }
      });
    },
    loadData() {
      if (this.refreshing) {
        this.dataList = [];
        this.current = 1;
        this.refreshing = false;
      }
      this.fetchData();
    },
    refreshData() {
      // 清空列表数据
      this.finished = false;
            
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.loadData();
    },
    statusIndex(item) {
      let index = 2;
      if(item.reviewed === 0) {
        index = 0;
      } else if(item.reviewed === 2){
        index = 1;
      } else if(item.status === 1){
        index = 2;
      } else if(item.status === 0){
        index = 3;
      }
      return index;
    },
    goToAdd() {
      this.$router.push('addSellerAccount');
    },
    goToDetail(item) {
      if (item.reviewed === 2) {
        this.$AppData.setDataStore({
          sellerAccount: item.sellerAccount,
          qrCode: item.qrCode,
          failMsg: item.failMsg,
          id: item.id,
        })
        this.$router.push('addSellerAccount');
      } else {
        this.detail = item;
        this.showDetail = true;
        // localStorage.setItem('sellerAccount', JSON.stringify(item));
        // this.$router.push('sellerAccountDetail');
      }
    },
    handleToggleSellerAccount() {
      if (this.detail.status == 1) {
        this.$myDialog.confirm({
          title: i18n.global.t('alert.warnning'),
          message: i18n.global.t('alert.open'),
        })
        .then(() => {// on confirm
          enableSellerAccount({
            id: this.detail.id,
          }).then(res => {
            this.showDetail = false;
            this.refreshing = true
            this.refreshData();
          });
        })
        .catch(() => {});
      } else {
        this.$myDialog.confirm({
          title: i18n.global.t('alert.warnning'),
          message: i18n.global.t('alert.close'),
        })
        .then(() => {// on confirm
          disableSellerAccount({
            id: this.detail.id,
          }).then(res => {
            this.showDetail = false;
            this.refreshing = true
            this.refreshData();
          });
        })
        .catch(() => {});
      }
    },
  },
};
</script>

<style lang="less">
.seller-account {
  padding-bottom: 50px;
  .user-info-wrap {
    line-height: 26px;
    padding: 20px 10px;
    color: #fff;
    background: var(--van-primary-color);
    .user-header-title {
      font-size: 22px;
      font-weight: 600;
    }
  }
  .seller-account-block {
    display: flex;
    align-items: center;
    line-height: 28px;
    padding: 12px 10px;
    margin: 10px 12px;
    font-size: 14px;
    background-color: #fff;
    border-radius: 6px;
    .seller-account-info {
      flex-grow: 1;
      .seller-account-name {
        font-size: 16px;
        font-weight: bold;
      }
      .seller-account-realname {
        color: #999;
      }
    }
    
    .seller-account-status {
      width: 80px;
      text-align: right;
      font-size: 14px;
      &.status0 {
        color: orange;
      }
      &.status2 {
        color: red;
      }
      &.status3 {
        color: green;
      }
    }
  }
  .detail-dialog {
    width: 330px;
    padding: 16px 0;
    border-radius: 9px;
    .detail-content {
      font-size: 14px;
      color: #6F6F93;
      font-weight: 400;
    }
    .van-cell {
      padding: 12px 20px;
      &.detail-account {
        border-bottom: 1px solid #d8d8d8;
      }
    }
    
    .van-cell__title {
      font-size:14px;
      font-weight:700;
      color:#3d3d3d;
    }
    .van-cell__value,
    .van-cell__label {
      color:#a5a5a5;
      font-size:13px;
      font-weight: normal;
    }
    .van-cell__label {
      display: flex;
      justify-content: space-between;
    }
    canvas {
      display: block;
      margin: 0 auto;
    }
    .van-button {
      display: block;
      margin: 16px auto;
      padding: 0 40px;
    }
  }
}
</style>
